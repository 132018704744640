import React from 'react';
import { Link } from 'react-router-dom';

function AllConversionsPage() {
  const conversions = [
    { id: 'ug-to-g', name: 'Ug to G' },
    { id: 'ug-to-mg', name: 'Ug to MG' },
    { id: 'ug-to-ng', name: 'Ug to NG' },
    { id: 'ug-to-kg', name: 'Ug to KG' },
    { id: 'ug-to-ml', name: 'Ug to ML' },
    { id: 'ug-to-iu', name: 'Ug to IU' },
    { id: 'ug-to-ul', name: 'Ug to UL' },
  ];

  return (
    <div className="all-conversions-page">
      <h1>All Conversions</h1>
      {conversions.map((conversion) => (
        <div key={conversion.id}>
          <h2>{conversion.name}</h2>
          <div className="conversion-links">
            {Array.from({ length: 2000 }, (_, index) => (
              <Link
                key={index}
                to={`/${conversion.id}/conversion/${index + 1}`}
                className="conversion-link"
              >
                {index + 1}
              </Link>
            ))}
          </div>
        </div>
      ))}
    </div>
  );
}

export default AllConversionsPage;