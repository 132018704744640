const React = require('react');
const { Helmet } = require('react-helmet');

function ContactPage() {
  const seoTitle = 'Contact Us | Ug-to.com';
  const seoDescription = 'Get in touch with us for any questions, suggestions, or inquiries related to ug conversions. Fill out our contact form or email us directly at harikezdigital@gmail.com for a prompt response.';

  const handleSubmit = (e) => {
    e.preventDefault();
    const form = e.target;
    const formData = new FormData(form);
    const name = formData.get('name');
    const email = formData.get('email');
    const message = formData.get('message');

    const mailtoLink = `mailto:harikezdigital@gmail.com?subject=Contact Form Submission&body=Name: ${name}%0D%0AEmail: ${email}%0D%0AMessage: ${message}`;
    window.location.href = mailtoLink;

    form.reset();
  };

  return (
    <div className="contact-page">
      <Helmet>
        <title>{seoTitle}</title>
        <meta name="description" content={seoDescription} />
      </Helmet>

      <section className="contact-content">
        <h1>Contact Us</h1>
        <p>We value your feedback, questions, and suggestions. If you have any inquiries related to ug conversions or our website, please don't hesitate to reach out to us. Our team is dedicated to providing you with the best possible assistance.</p>
      </section>

      <section className="contact-form">
        <h2>Send us a Message</h2>
        <form onSubmit={handleSubmit}>
          <div className="form-group">
            <label htmlFor="name">Name</label>
            <input type="text" id="name" name="name" placeholder="Enter your name" required />
          </div>
          <div className="form-group">
            <label htmlFor="email">Email</label>
            <input type="email" id="email" name="email" placeholder="Enter your email" required />
          </div>
          <div className="form-group">
            <label htmlFor="message">Message</label>
            <textarea id="message" name="message" placeholder="Enter your message" required></textarea>
          </div>
          <button type="submit">Send Message</button>
        </form>
      </section>

      <section className="contact-info">
        <h2>Contact Information</h2>
        <p>If you prefer to contact us directly, you can reach us via email:</p>
        <a href="mailto:harikezdigital@gmail.com">harikezdigital@gmail.com</a>
        <p>We strive to respond to all inquiries within 24-48 hours. Your satisfaction is our top priority, and we appreciate your patience as we work to address your concerns.</p>
      </section>
    </div>
  );
}

export default ContactPage;