import React, { useState } from 'react';
import './HomePage.css';
const seoTitle = 'Ug Conversion Calculator - Ug to Other Conversions';
  const seoDescription = 'Convert Ug to various units such as grams, milligrams, nanograms, and more with our easy-to-use Ug conversion calculator.';


function HomePage() {
  const [selectedConversion, setSelectedConversion] = useState('');
  const [inputValue, setInputValue] = useState('');
  const [outputValue, setOutputValue] = useState('');

  const handleConversionChange = (event) => {
    setSelectedConversion(event.target.value);
  };

  const handleInputChange = (event) => {
    setInputValue(event.target.value);
  };

  const handleConvert = () => {
    // Perform the conversion logic based on the selected conversion
    let convertedValue;

    switch (selectedConversion) {
      case 'ug-to-g':
        convertedValue = inputValue / 1000000;
        break;
      case 'ug-to-mg':
        convertedValue = inputValue / 1000;
        break;
      case 'ug-to-ng':
        convertedValue = inputValue * 1000;
        break;
      case 'ug-to-kg':
        convertedValue = inputValue / 1000000000;
        break;
      case 'ug-to-ml':
        convertedValue = inputValue / 1000;
        break;
      case 'ug-to-iu':
        convertedValue = inputValue * 0.001;
        break;
      case 'ug-to-ul':
        convertedValue = inputValue;
        break;
      default:
        convertedValue = '';
    }

    setOutputValue(convertedValue);
  };

  const getConversionName = () => {
    switch (selectedConversion) {
      case 'ug-to-g':
        return 'g';
      case 'ug-to-mg':
        return 'mg';
      case 'ug-to-ng':
        return 'ng';
      case 'ug-to-kg':
        return 'kg';
      case 'ug-to-ml':
        return 'ml';
      case 'ug-to-iu':
        return 'IU';
      case 'ug-to-ul':
        return 'ul';
      default:
        return '';
    }
  };

  return (
    <div className="home-page">
    <head>
        <title>{seoTitle}</title>
        <meta name="description" content={seoDescription} />
      </head>
      <section className="hero">
        <h1 className="hero-title">Ug to Calculator</h1>
        <p className="hero-description">
          Convert Ug to various units easily with our conversion tool. Simply enter the Ug value, select the desired unit, and get the converted result instantly!
        </p>
        <p className="hero-description">
          Our Ug conversion calculator supports a wide range of units, including grams (g), milligrams (mg), kilograms (kg), and more. Whether you need to convert Ug for scientific, medical, or any other purpose, our tool has got you covered.
        </p>
        <div className="conversion-card">
          <div className="input-container">
            <input
              type="number"
              placeholder="Enter Ug value"
              value={inputValue}
              onChange={handleInputChange}
            />
            <span className="to-text">To</span>
            <select value={selectedConversion} onChange={handleConversionChange}>
              <option value="">Select a unit</option>
              <option value="ug-to-g">g</option>
              <option value="ug-to-mg">mg</option>
              <option value="ug-to-ng">ng</option>
              <option value="ug-to-kg">kg</option>
              <option value="ug-to-ml">ml</option>
              <option value="ug-to-iu">IU</option>
              <option value="ug-to-ul">ul</option>
            </select>
          </div>
          <button className="convert-button" onClick={handleConvert}>
            Convert
          </button>
          {outputValue && (
            <p className="result-text">
              {inputValue} ug to {getConversionName()} is {outputValue}
            </p>
          )}
        </div>
      </section>
      
      <section className="other-conversions">
        <h2 className="other-conversions-title">Other Ug Conversion Tools</h2>
        <div className="conversions-grid">
          <a href="/ug-to-g" className="conversion-link">
            <img src="https://i.ibb.co/q0t3Ghp/pngwing-com.png" alt="Icon" />
            <span>Ug to Grams (g)</span>
          </a>
          <a href="/ug-to-mg" className="conversion-link">
            <img src="https://i.ibb.co/q0t3Ghp/pngwing-com.png" alt="Icon" />
            <span>Ug to Milligrams (mg)</span>
          </a>
          <a href="/ug-to-ng" className="conversion-link">
            <img src="https://i.ibb.co/q0t3Ghp/pngwing-com.png" alt="Icon" />
            <span>Ug to Nanograms (ng)</span>
          </a>
          <a href="/ug-to-kg" className="conversion-link">
            <img src="https://i.ibb.co/q0t3Ghp/pngwing-com.png" alt="Icon" />
            <span>Ug to Kilograms (kg)</span>
          </a>
          <a href="/ug-to-ml" className="conversion-link">
            <img src="https://i.ibb.co/q0t3Ghp/pngwing-com.png" alt="Icon" />
            <span>Ug to Milliliters (ml)</span>
          </a>
          <a href="/ug-to-iu" className="conversion-link">
            <img src="https://i.ibb.co/q0t3Ghp/pngwing-com.png" alt="Icon" />
            <span>Ug to International Units (IU)</span>
          </a>
          <a href="/ug-to-ul" className="conversion-link">
            <img src="https://i.ibb.co/q0t3Ghp/pngwing-com.png" alt="Icon" />
            <span>Ug to Microliters (ul)</span>
          </a>
        </div>
      </section>

      <section className="conversion-calculator">
        <h2 className="conversion-calculator-title">Ug Conversion Calculator</h2>
        <div className="conversion-calculator-grid">
          <div className="conversion-calculator-item">
            <h3>Ug to Grams (g)</h3>
            <p>Convert Ug to Grams (g) easily with our conversion tool. Simply enter the Ug value and get the equivalent value in Grams (g).</p>
          </div>
          <div className="conversion-calculator-item">
            <h3>Ug to Milligrams (mg)</h3>
            <p>Convert Ug to Milligrams (mg) with just a few clicks. Our conversion tool makes it easy to get accurate results.</p>
          </div>
          <div className="conversion-calculator-item">
            <h3>Ug to Nanograms (ng)</h3>
            <p>Need to convert Ug to Nanograms (ng)? Our conversion tool has got you covered. Enter the Ug value and get the result instantly.</p>
          </div>
          <div className="conversion-calculator-item">
            <h3>Ug to Kilograms (kg)</h3>
            <p>Convert Ug to Kilograms (kg) with ease using our reliable conversion tool. Get accurate results in no time.</p>
          </div>
          <div className="conversion-calculator-item">
            <h3>Ug to Milliliters (ml)</h3>
            <p>Convert Ug to Milliliters (ml) with confidence using our precise conversion tool. Enter the Ug value and get the equivalent value in Milliliters (ml).</p>
          </div>
          <div className="conversion-calculator-item">
            <h3>Ug to International Units (IU)</h3>
            <p>Need to convert Ug to International Units (IU)? Our conversion tool makes it simple and straightforward. Get accurate results quickly.</p>
          </div>
          <div className="conversion-calculator-item">
            <h3>Ug to Microliters (ul)</h3>
            <p>Convert Ug to Microliters (ul) effortlessly with our user-friendly conversion tool. Enter the Ug value and get the equivalent value in Microliters (ul).</p>
          </div>
        </div>
      </section>
    </div>
  );
}

export default HomePage;