const React = require('react');
const { Helmet } = require('react-helmet');

function PolicyPage() {
    const seoTitle = 'Privacy Policy | Ug-to.com';
    const seoDescription = 'Read our privacy policy to understand how we handle your data and ensure the security of your information when using our ug conversion tools.';
  
    return React.createElement(
      'div',
      { className: 'policy-page' },
      React.createElement(
        Helmet,
        null,
        React.createElement('title', null, seoTitle),
        React.createElement('meta', { name: 'description', content: seoDescription })
      ),
      React.createElement(
        'section',
        { className: 'policy-content' },
        React.createElement('h1', null, 'Privacy Policy'),
        React.createElement(
          'p',
          null,
          'At ugconversion.com, we prioritize the privacy and security of our users. This privacy policy outlines how we collect, use, and protect your personal information when you use our website and conversion tools.'
        ),
        React.createElement(
          'h2',
          null,
          'Information We Collect'
        ),
        React.createElement(
          'p',
          null,
          'We do not collect any personal information from our users. Our website does not require registration or any form of personal data submission to access and use our conversion tools.'
        ),
        React.createElement(
          'h2',
          null,
          'Cookies'
        ),
        React.createElement(
          'p',
          null,
          'We use cookies on our website to enhance your user experience and improve the functionality of our conversion tools. These cookies do not collect any personal information and are solely used for the purpose of providing a seamless and efficient user experience.'
        ),
        React.createElement(
          'h2',
          null,
          'Third-Party Services'
        ),
        React.createElement(
          'p',
          null,
          'We may use third-party services, such as Google Analytics, to analyze website traffic and improve our services. These third-party services may collect anonymous information about your visit to our website, but they do not collect any personal data that can be used to identify you.'
        ),
        React.createElement(
          'h2',
          null,
          'Data Security'
        ),
        React.createElement(
          'p',
          null,
          'We implement industry-standard security measures to protect the integrity and confidentiality of our website and conversion tools. However, please be aware that no method of transmission over the internet or electronic storage is 100% secure, and we cannot guarantee absolute security.'
        ),
        React.createElement(
          'h2',
          null,
          'Changes to the Privacy Policy'
        ),
        React.createElement(
          'p',
          null,
          'We reserve the right to update or modify this privacy policy at any time. Any changes will be effective immediately upon posting the revised policy on our website.'
        ),
        React.createElement(
          'h2',
          null,
          'Contact Us'
        ),
        React.createElement(
          'p',
          null,
          'If you have any questions or concerns regarding our privacy policy or the practices of our website, please contact us at harikezdigital@gmail.com.'
        )
      )
    );
  }
  export default PolicyPage;
