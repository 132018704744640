const React = require('react');
const { Helmet } = require('react-helmet');

function AboutPage() {
  const seoTitle = 'About Us | Ug-to.com';
  const seoDescription = 'Discover the story behind Ug-to.com, your go-to resource for accurate and user-friendly ug conversions. Learn about our mission, team, and commitment to providing reliable conversion tools.';

  return (
    <div className="about-page">
      <Helmet>
        <title>{seoTitle}</title>
        <meta name="description" content={seoDescription} />
      </Helmet>

      <section className="hero">
        <h1>About Ug-to.com</h1>
        <p>Your Trusted Resource for Ug Conversions</p>
      </section>

      <section className="about-content">
        <div className="card">
          <h2>Our Mission</h2>
          <p>At Ug-to.com, our mission is to provide accurate and user-friendly conversion tools for individuals and professionals working with ug measurements. We understand the importance of reliable conversions in various fields, including science, research, and everyday applications.</p>
        </div>

        <div className="card">
          <h2>Why Choose Us?</h2>
          <ul>
            <li>Accurate and precise conversions</li>
            <li>User-friendly interface</li>
            <li>Wide range of conversion units</li>
            <li>Constantly updated conversion factors</li>
            <li>Mobile-friendly design</li>
          </ul>
        </div>

        <div className="card">
          <h2>Our Team</h2>
          <p>Our team consists of experienced developers and math enthusiasts who are passionate about providing the best conversion tools. We combine our expertise in web development and mathematical algorithms to ensure that our conversions are accurate and reliable.</p>
        </div>

        <div className="card">
          <h2>Conversion Units</h2>
          <p>At Ug-to.com, we offer conversions from ug to a wide range of units, including:</p>
          <ul>
            <li>Grams (g)</li>
            <li>Milligrams (mg)</li>
            <li>Nanograms (ng)</li>
            <li>Pounds (lb)</li>
            <li>Ounces (oz)</li>
            <li>And more!</li>
          </ul>
        </div>

        <div className="card">
          <h2>Our Commitment</h2>
          <p>We are committed to providing the most accurate and up-to-date conversion tools. Our team regularly reviews and updates our conversion factors to ensure that you always get the most precise results. We value your feedback and continuously strive to improve our platform to meet your needs.</p>
        </div>
      </section>

      <section className="contact">
        <h2>Contact Us</h2>
        <p>If you have any questions, suggestions, or feedback, please don't hesitate to reach out to us. We value your input and are always here to assist you.</p>
        <ul>
          <li>Email: info@ug-to.com</li>
          <li>Phone: +1 (123) 456-7890</li>
          <li>Address: 123 Conversion Street, City, Country</li>
        </ul>
      </section>
    </div>
  );
}

export default AboutPage;