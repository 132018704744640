import React from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import './App.css';
import Header from './components/Header';
import HomePage from './components/HomePage';
import Footer from './components/Footer';
import ContactPage from './components/ContactPage';
import AboutPage from './components/AboutPage';
import PolicyPage from './components/PolicyPage';
import './components/ConversionPage.css';
import ConversionPage from './components/ConversionPage';
import AllConversionsPage from './components/AllConversionsPage';

function App() {
  return (
    <Router>
      <div className="App">
        <Header />
        <Routes>
          <Route path="/" element={<HomePage />} />
          <Route path="/ug-to-g" element={<ConversionPage conversion={{ id: 'ug-to-g', name: 'Ug to g' }} />} />
          <Route path="/ug-to-mg" element={<ConversionPage conversion={{ id: 'ug-to-mg', name: 'Ug to mg' }} />} />
          <Route path="/ug-to-ng" element={<ConversionPage conversion={{ id: 'ug-to-ng', name: 'Ug to ng' }} />} />
          <Route path="/ug-to-kg" element={<ConversionPage conversion={{ id: 'ug-to-kg', name: 'Ug to kg' }} />} />
          <Route path="/ug-to-ml" element={<ConversionPage conversion={{ id: 'ug-to-ml', name: 'Ug to ml' }} />} />
          <Route path="/ug-to-iu" element={<ConversionPage conversion={{ id: 'ug-to-iu', name: 'Ug to iu' }} />} />
          <Route path="/ug-to-ul" element={<ConversionPage conversion={{ id: 'ug-to-ul', name: 'Ug to ul' }} />} />
          <Route path="/ug-to-g/conversion/:value" element={<ConversionPage conversion={{ id: 'ug-to-g', name: 'Ug to g' }} />} />
          <Route path="/ug-to-mg/conversion/:value" element={<ConversionPage conversion={{ id: 'ug-to-mg', name: 'Ug to mg' }} />} />
          <Route path="/ug-to-ng/conversion/:value" element={<ConversionPage conversion={{ id: 'ug-to-ng', name: 'Ug to ng' }} />} />
          <Route path="/ug-to-kg/conversion/:value" element={<ConversionPage conversion={{ id: 'ug-to-kg', name: 'Ug to kg' }} />} />
          <Route path="/ug-to-ml/conversion/:value" element={<ConversionPage conversion={{ id: 'ug-to-ml', name: 'Ug to ml' }} />} />
          <Route path="/ug-to-iu/conversion/:value" element={<ConversionPage conversion={{ id: 'ug-to-iu', name: 'Ug to iu' }} />} />
          <Route path="/ug-to-ul/conversion/:value" element={<ConversionPage conversion={{ id: 'ug-to-ul', name: 'Ug to ul' }} />} />
          <Route path="/all-conversions" element={<AllConversionsPage />} />
          <Route path="/contact" element={<ContactPage />} />
          <Route path="/about" element={<AboutPage />} />
          <Route path="/privacy" element={<PolicyPage />} />
        </Routes>
        <Footer />
      </div>
    </Router>
  );
}

export default App;