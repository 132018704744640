const React = require('react');
const { Helmet } = require('react-helmet');

const { useParams, Link } = require('react-router-dom');


function getConversionFullName(conversionId) {
  switch (conversionId) {
    case 'ug-to-g':
      return 'Grams (g)';
    case 'ug-to-mg':
      return 'Milligrams (mg)';
    case 'ug-to-ng':
      return 'Nanograms (ng)';
    case 'ug-to-kg':
      return 'Kilograms (kg)';
    case 'ug-to-ml':
      return 'Milliliters (ml)';
    case 'ug-to-iu':
      return 'International Units (IU)';
    case 'ug-to-ul':
      return 'Microliters (ul)';
    default:
      return '';
  }
}

function ConversionPage({ conversion }) {
  const { value } = useParams();
  const [inputValue, setInputValue] = React.useState(value || '');
  const [convertedValue, setConvertedValue] = React.useState('');
  const [activeIndex, setActiveIndex] = React.useState(null);

  const toggleFAQ = (index) => {
    setActiveIndex(activeIndex === index ? null : index);
  };

  const handleInputChange = (event) => {
    const newValue = event.target.value;
    setInputValue(newValue);
    convertValue(newValue);
  };

  const convertValue = (value) => {
    let result;

    switch (conversion.id) {
      case 'ug-to-g':
        result = value / 1000000;
        break;
      case 'ug-to-mg':
        result = value / 1000;
        break;
      case 'ug-to-ng':
        result = value * 1000;
        break;
      case 'ug-to-kg':
        result = value / 1000000000;
        break;
      case 'ug-to-ml':
        result = value / 1000;
        break;
      case 'ug-to-iu':
        result = value * 0.001;
        break;
      case 'ug-to-ul':
        result = value;
        break;
      default:
        result = '';
    }

    setConvertedValue(result);
  };
  const conversionSteps = () => {
    switch (conversion.id) {
      case 'ug-to-g':
      return (
        <div>
          <h3>Calculation steps:</h3>
          <ol style={{ textAlign: 'left', marginLeft: '25px' }}>
            <li>1 μg = 0.000001 g</li>
            <li>{inputValue} μg = {inputValue} × 0.000001 g</li>
            <li>{inputValue} μg = {convertedValue} g</li>
          </ol>
        </div>
      );
    case 'ug-to-mg':
      return (
        <div>
          <h3>Calculation steps:</h3>
          <ol style={{ textAlign: 'left', marginLeft: '25px' }}>
            <li>1 μg = 0.001 mg</li>
            <li>{inputValue} μg = {inputValue} × 0.001 mg</li>
            <li>{inputValue} μg = {convertedValue} mg</li>
          </ol>
        </div>
      );
        case 'ug-to-ng':
          return (
            <div>
              <h3>Calculation steps:</h3>
              <ol style={{ textAlign: 'left', marginLeft: '25px' }}>
                <li>1 μg = 1000 ng</li>
                <li>{inputValue} μg = {inputValue} × 1000 ng</li>
                <li>{inputValue} μg = {convertedValue} ng</li>
              </ol>
            </div>
          );
        case 'ug-to-kg':
          return (
            <div>
              <h3>Calculation steps:</h3>
              <ol style={{ textAlign: 'left', marginLeft: '25px' }}>
                <li>1 μg = 0.000000001 kg</li>
                <li>{inputValue} μg = {inputValue} × 0.000000001 kg</li>
                <li>{inputValue} μg = {convertedValue} kg</li>
              </ol>
            </div>
          );
        case 'ug-to-ml':
          return (
            <div>
              <h3>Calculation steps:</h3>
              <ol style={{ textAlign: 'left', marginLeft: '25px' }}>
                <li>1 μg = 0.001 ml</li>
                <li>{inputValue} μg = {inputValue} × 0.001 ml</li>
                <li>{inputValue} μg = {convertedValue} ml</li>
              </ol>
            </div>
          );
        case 'ug-to-iu':
          return (
            <div>
              <h3>Calculation steps:</h3>
              <ol style={{ textAlign: 'left', marginLeft: '25px' }}>
                <li>1 μg = 0.001 IU</li>
                <li>{inputValue} μg = {inputValue} × 0.001 IU</li>
                <li>{inputValue} μg = {convertedValue} IU</li>
              </ol>
            </div>
          );
        case 'ug-to-ul':
          return (
            <div>
              <h3>Calculation steps:</h3>
              <ol style={{ textAlign: 'left', marginLeft: '25px' }}>
                <li>1 μg = 1 μl</li>
                <li>{inputValue} μg = {inputValue} × 1 μl</li>
                <li>{inputValue} μg = {convertedValue} μl</li>
              </ol>
            </div>
          );
        default:
          return null;
      }
    };

  React.useEffect(() => {
    if (value) {
      setInputValue(value);
      convertValue(value);
    }
  }, [value]);

  const copyToClipboard = () => {
    navigator.clipboard.writeText(convertedValue);
    alert('Converted value copied to clipboard!');
  };

  const conversionFullName = getConversionFullName(conversion.id);
  const conversionFormula = getConversionFormula(conversion.id);
  const seoTitle = value
    ? `${value} ug to ${conversionFullName} Conversion Calculator`
    : `ug to ${conversionFullName} Calculator`;

  const seoDescription = value
    ? `Convert ${value} ug to ${conversionFullName} with our precise conversion calculator. Get instant results and explore other ug conversions.`
    : `Convert ug to ${conversionFullName} easily with our reliable calculator. Enter any ug value and get the converted result instantly.`;
    


    return React.createElement(
      'div',
      { className: 'conversion-page' },
      React.createElement(
        Helmet,
        null,
        React.createElement('title', null, seoTitle),
        React.createElement('meta', { name: 'description', content: seoDescription })
      ),
      React.createElement(
        'section',
        { className: 'breadcrumbs' },
        React.createElement(
          'div',
          null,
          React.createElement(
            Link,
            { to: '/' },
            'Home'
          ),
          ' > ',
          React.createElement(
            Link,
            { to: `/${conversion.id}` },
            conversionFullName
          ),
          ' > ',
          inputValue
        )
      ),
      React.createElement(
        'section',
        { className: 'hero' },
        React.createElement('h1', { className: 'hero-title' }, `Ug to ${conversionFullName}`),
        React.createElement(
          'p',
          { className: 'hero-description' },
          `Convert Ug to ${conversionFullName} easily with our conversion tool. Simply enter the Ug value and get the converted result instantly!`
        ),
        React.createElement(
          'div',
          { className: 'conversion-card' },
          React.createElement(
            'div',
            { className: 'input-container' },
            React.createElement('input', {
              type: 'number',
              placeholder: 'Enter Ug value',
              value: inputValue,
              onChange: handleInputChange
            }),
            React.createElement('span', { className: 'to-text' }, 'To'),
            React.createElement(
              'select',
              { value: conversion.id, disabled: true },
              React.createElement('option', { value: conversion.id }, conversionFullName)
            )
          ),
          React.createElement(
            'p',
            { className: 'result-text' },
            `${inputValue} ug to ${conversionFullName} is ${convertedValue}`
          ),
          React.createElement(conversionSteps),
          React.createElement(
            'button',
            { className: 'copy-button', onClick: copyToClipboard },
            'Copy Answer'
          ),
          React.createElement(
            'p',
            { className: 'conversion-formula' },
            conversionFormula
          )
        )
      ),
    
  
    React.createElement(
      'section',
      { className: 'other-conversions' },
      React.createElement('h2', { className: 'other-conversions-title' }, 'Other Ug Conversion Tools'),
      React.createElement(
        'div',
        { className: 'conversions-grid' },
        React.createElement(
          'a',
          { href: '/ug-to-g', className: 'conversion-link' },
          React.createElement('img', { src: 'https://i.ibb.co/q0t3Ghp/pngwing-com.png', alt: 'Icon' }),
          React.createElement('span', null, 'Ug to Grams (g)')
        ),
        React.createElement(
          'a',
          { href: '/ug-to-mg', className: 'conversion-link' },
          React.createElement('img', { src: 'https://i.ibb.co/q0t3Ghp/pngwing-com.png', alt: 'Icon' }),
          React.createElement('span', null, 'Ug to Milligrams (mg)')
        ),
        React.createElement(
          'a',
          { href: '/ug-to-ng', className: 'conversion-link' },
          React.createElement('img', { src: 'https://i.ibb.co/q0t3Ghp/pngwing-com.png', alt: 'Icon' }),
          React.createElement('span', null, 'Ug to Nanograms (ng)')
        ),
        React.createElement(
          'a',
          { href: '/ug-to-kg', className: 'conversion-link' },
          React.createElement('img', { src: 'https://i.ibb.co/q0t3Ghp/pngwing-com.png', alt: 'Icon' }),
          React.createElement('span', null, 'Ug to Kilograms (kg)')
        ),
        React.createElement(
          'a',
          { href: '/ug-to-ml', className: 'conversion-link' },
          React.createElement('img', { src: 'https://i.ibb.co/q0t3Ghp/pngwing-com.png', alt: 'Icon' }),
          React.createElement('span', null, 'Ug to Milliliters (ml)')
        ),
        React.createElement(
          'a',
          { href: '/ug-to-iu', className: 'conversion-link' },
          React.createElement('img', { src: 'https://i.ibb.co/q0t3Ghp/pngwing-com.png', alt: 'Icon' }),
          React.createElement('span', null, 'Ug to International Units (IU)')
        ),
        React.createElement(
          'a',
          { href: '/ug-to-ul', className: 'conversion-link' },
          React.createElement('img', { src: 'https://i.ibb.co/q0t3Ghp/pngwing-com.png', alt: 'Icon' }),
          React.createElement('span', null, 'Ug to Microliters (ul)')
        )
      )
    ),
    React.createElement(
      'section',
      { className: 'popular-items' },
      React.createElement('h2', { className: 'popular-items-title' }, `Popular Items with measures by Ug to ${conversionFullName}`),
      React.createElement(
        'div',
        { className: 'popular-items-grid' },
        conversion.id === 'ug-to-g' && React.createElement(
          React.Fragment,
          null,
          React.createElement(
            'div',
            { className: 'popular-item' },
            React.createElement('h3', null, 'Gold'),
            React.createElement('p', null, 'The mass of gold is often measured in micrograms (μg) for precise weighing in industries such as jewelry making and scientific research.')
          ),
          React.createElement(
            'div',
            { className: 'popular-item' },
            React.createElement('h3', null, 'Pharmaceutical Ingredients'),
            React.createElement('p', null, 'Active pharmaceutical ingredients (APIs) and other chemical compounds are frequently measured in micrograms for accurate dosing and formulation.')
          ),
          React.createElement(
            'div',
            { className: 'popular-item' },
            React.createElement('h3', null, 'Pollutants'),
            React.createElement('p', null, 'Micrograms are used to measure the presence of pollutants, such as heavy metals or pesticides, in environmental samples or food products.')
          ),
          React.createElement(
            'div',
            { className: 'popular-item' },
            React.createElement('h3', null, 'Forensic Evidence'),
            React.createElement('p', null, 'Trace amounts of substances, like drugs or explosives, are often measured in micrograms in forensic investigations.')
          ),
          React.createElement(
            'div',
            { className: 'popular-item' },
            React.createElement('h3', null, 'Cosmetic Ingredients'),
            React.createElement('p', null, 'The concentration of active ingredients in cosmetic products, such as retinol or hyaluronic acid, is sometimes expressed in micrograms.')
          )
        ),
        conversion.id === 'ug-to-mg' && React.createElement(
          React.Fragment,
          null,
          React.createElement(
            'div',
            { className: 'popular-item' },
            React.createElement('h3', null, 'Vitamins and Supplements'),
            React.createElement('p', null, 'The content of vitamins and dietary supplements is often expressed in micrograms, such as Vitamin B12 or folic acid.')
          ),
          React.createElement(
            'div',
            { className: 'popular-item' },
            React.createElement('h3', null, 'Biological Samples'),
            React.createElement('p', null, 'Micrograms are used to measure the mass of small biological samples, such as DNA, RNA, or proteins, in research and diagnostic applications.')
          ),
          React.createElement(
            'div',
            { className: 'popular-item' },
            React.createElement('h3', null, 'Pesticide Residues'),
            React.createElement('p', null, 'The amount of pesticide residues on fruits and vegetables is often measured in micrograms per kilogram (μg/kg) to ensure food safety.')
          ),
          React.createElement(
            'div',
            { className: 'popular-item' },
            React.createElement('h3', null, 'Fragrance Compounds'),
            React.createElement('p', null, 'The concentration of fragrance compounds in perfumes and personal care products is sometimes expressed in micrograms.')
          ),
          React.createElement(
            'div',
            { className: 'popular-item' },
            React.createElement('h3', null, 'Nanoparticles'),
            React.createElement('p', null, 'Micrograms are used to measure the mass of nanoparticles in various applications, such as drug delivery systems or advanced materials.')
          )
        ),
        conversion.id === 'ug-to-ng' && React.createElement(
          React.Fragment,
          null,
          React.createElement(
            'div',
            { className: 'popular-item' },
            React.createElement('h3', null, 'Hormones'),
            React.createElement('p', null, 'The concentration of hormones, such as testosterone or estrogen, is often measured in nanograms per milliliter (ng/mL) in medical laboratories.')
          ),
          React.createElement(
            'div',
            { className: 'popular-item' },
            React.createElement('h3', null, 'Toxins'),
            React.createElement('p', null, 'The presence of toxins, like mycotoxins or marine biotoxins, is sometimes quantified in nanograms to assess contamination levels.')
          ),
          React.createElement(
            'div',
            { className: 'popular-item' },
            React.createElement('h3', null, 'Environmental Contaminants'),
            React.createElement('p', null, 'Nanograms are used to measure trace amounts of environmental contaminants, such as dioxins or polychlorinated biphenyls (PCBs), in soil, water, or air samples.')
          ),
          React.createElement(
            'div',
            { className: 'popular-item' },
            React.createElement('h3', null, 'Biomarkers'),
            React.createElement('p', null, 'The levels of specific biomarkers, like proteins or metabolites, are often expressed in nanograms per milliliter (ng/mL) in clinical diagnostics.')
          ),
          React.createElement(
            'div',
            { className: 'popular-item' },
            React.createElement('h3', null, 'Pesticides'),
            React.createElement('p', null, 'The concentration of pesticides in water or food samples is sometimes measured in nanograms per liter (ng/L) or nanograms per gram (ng/g).')
          )
        ),
        conversion.id === 'ug-to-kg' && React.createElement(
          React.Fragment,
          null,
          React.createElement(
            'div',
            { className: 'popular-item' },
            React.createElement('h3', null, 'Food Additives'),
            React.createElement('p', null, 'The amount of food additives, such as preservatives or antioxidants, is often regulated in micrograms per kilogram (μg/kg) of the food product.')
          ),
          React.createElement(
            'div',
            { className: 'popular-item' },
            React.createElement('h3', null, 'Pesticide Residues'),
            React.createElement('p', null, 'The maximum residue levels (MRLs) for pesticides in agricultural products are typically expressed in micrograms per kilogram (μg/kg).')
          ),
          React.createElement(
            'div',
            { className: 'popular-item' },
            React.createElement('h3', null, 'Heavy Metals'),
            React.createElement('p', null, 'The concentration of heavy metals, like lead or cadmium, in soil or food samples is often measured in micrograms per kilogram (μg/kg).')
          ),
          React.createElement(
            'div',
            { className: 'popular-item' },
            React.createElement('h3', null, 'Veterinary Drugs'),
            React.createElement('p', null, 'The residue levels of veterinary drugs in animal-derived food products are usually regulated in micrograms per kilogram (μg/kg).')
          ),
          React.createElement(
            'div',
            { className: 'popular-item' },
            React.createElement('h3', null, 'Mycotoxins'),
            React.createElement('p', null, 'The maximum allowable levels of mycotoxins, such as aflatoxins or ochratoxins, in food commodities are often set in micrograms per kilogram (μg/kg).')
          )
        ),
        conversion.id === 'ug-to-ml' && React.createElement(
          React.Fragment,
          null,
          React.createElement(
            'div',
            { className: 'popular-item' },
            React.createElement('h3', null, 'Essential Oils'),
            React.createElement('p', null, 'The concentration of essential oils in aromatherapy products or perfumes is sometimes expressed in micrograms per milliliter (μg/mL).')
          ),
          React.createElement(
            'div',
            { className: 'popular-item' },
            React.createElement('h3', null, 'Pharmaceutical Formulations'),
            React.createElement('p', null, 'The content of active ingredients in liquid pharmaceutical formulations, like syrups or injections, is often measured in micrograms per milliliter (μg/mL).')
          ),
          React.createElement(
            'div',
            { className: 'popular-item' },
            React.createElement('h3', null, 'Flavor Extracts'),
            React.createElement('p', null, 'The strength of flavor extracts, such as vanilla or peppermint, is sometimes expressed in micrograms per milliliter (μg/mL).')
          ),
          React.createElement(
            'div',
            { className: 'popular-item' },
            React.createElement('h3', null, 'Pesticide Solutions'),
            React.createElement('p', null, 'The concentration of pesticides in liquid formulations, like sprays or dips, is often measured in micrograms per milliliter (μg/mL).')
          ),
          React.createElement(
            'div',
            { className: 'popular-item' },
            React.createElement('h3', null, 'Fragrance Oils'),
            React.createElement('p', null, 'The concentration of fragrance oils in cosmetic products or candles is sometimes expressed in micrograms per milliliter (μg/mL).')
          )
        ),
        conversion.id === 'ug-to-iu' && React.createElement(
          React.Fragment,
          null,
          React.createElement(
            'div',
            { className: 'popular-item' },
            React.createElement('h3', null, 'Vitamin D'),
            React.createElement('p', null, 'The biological activity of vitamin D is often measured in international units (IU), with 1 IU equivalent to 0.025 micrograms of vitamin D.')
          ),
          React.createElement(
            'div',
            { className: 'popular-item' },
            React.createElement('h3', null, 'Vitamin A'),
            React.createElement('p', null, 'The potency of vitamin A in supplements or fortified foods is sometimes expressed in international units (IU), with 1 IU equal to 0.3 micrograms of retinol.')
          ),
          React.createElement(
            'div',
            { className: 'popular-item' },
            React.createElement('h3', null, 'Vitamin E'),
            React.createElement('p', null, 'The antioxidant activity of vitamin E is often measured in international units (IU), with 1 IU corresponding to about 0.67 milligrams of d-alpha-tocopherol.')
          ),
          React.createElement(
            'div',
            { className: 'popular-item' },
            React.createElement('h3', null, 'Insulin'),
            React.createElement('p', null, 'The potency of insulin preparations is standardized in international units (IU), with 1 IU of insulin equivalent to 0.0347 milligrams of pure crystalline insulin.')
          ),
          React.createElement(
            'div',
            { className: 'popular-item' },
            React.createElement('h3', null, 'Heparin'),
            React.createElement('p', null, 'The anticoagulant activity of heparin is often expressed in international units (IU), with the specific conversion factor depending on the type of heparin.')
          )
        ),
        conversion.id === 'ug-to-ul' && React.createElement(
          React.Fragment,
          null,
          React.createElement(
            'div',
            { className: 'popular-item' },
            React.createElement('h3', null, 'Pipetting'),
            React.createElement('p', null, 'Micrograms and microliters are commonly used units in pipetting applications, where small volumes of liquids are measured and transferred.')
          ),
          React.createElement(
            'div',
            { className: 'popular-item' },
            React.createElement('h3', null, 'Enzyme Assays'),
            React.createElement('p', null, 'The activity of enzymes in biological assays is often expressed in micrograms per microliter (μg/μL) or units per microliter (U/μL).')
          ),
          React.createElement(
            'div',
            { className: 'popular-item' },
            React.createElement('h3', null, 'Nucleic Acid Quantification'),
            React.createElement('p', null, 'The concentration of DNA or RNA samples is frequently measured in micrograms per microliter (μg/μL) using spectrophotometric methods.')
          ),
          React.createElement(
            'div',
            { className: 'popular-item' },
            React.createElement('h3', null, 'Protein Quantification'),
            React.createElement('p', null, 'The concentration of protein solutions is often expressed in micrograms per microliter (μg/μL) for applications like electrophoresis or immunoassays.')
          ),
          React.createElement(
            'div',
            { className: 'popular-item' },
            React.createElement('h3', null, 'Drug Dosing'),
            React.createElement('p', null, 'The concentration of drugs in small-volume parenteral formulations, such as eye drops or injections, is sometimes measured in micrograms per microliter (μg/μL).')
          )
        )
      )
    ),
    React.createElement(
      'section',
      { className: 'trending-conversions' },
      React.createElement('h2', { className: 'trending-conversions-title' }, `Trending Ug to ${conversionFullName} Conversions`),
      React.createElement(
        'div',
        { className: 'trending-conversions-grid' },
        Array.from({ length: 20 }, (_, index) => {
          const value = (index + 1) * 10;
          return React.createElement(
            Link,
            {
              key: value,
              to: `/${conversion.id}/conversion/${value}`,
              className: 'trending-conversion'
            },
            `${value} Ug to ${conversionFullName}`
          );
        })
      )
    ),
    
    React.createElement(
      'section',
      { className: 'faq-section' },
      React.createElement('h2', { className: 'faq-title' }, 'Frequently Asked Questions'),
      React.createElement(
        'div',
        { className: 'faq-grid' },
        React.createElement(
          'div',
          {
            className: `faq-item ${activeIndex === 0 ? 'active' : ''}`,
            onClick: () => toggleFAQ(0),
          },
          React.createElement('h3', null, 'What is Ug?'),
          React.createElement(
            'p',
            { style: { display: activeIndex === 0 ? 'block' : 'none' } },
            'Ug, or microgram, is a unit of mass equal to one millionth of a gram (0.000001 g).'
          )
        ),
        React.createElement(
          'div',
          {
            className: `faq-item ${activeIndex === 1 ? 'active' : ''}`,
            onClick: () => toggleFAQ(1),
          },
          React.createElement('h3', null, `How do I convert Ug to ${conversionFullName}?`),
          React.createElement(
            'p',
            { style: { display: activeIndex === 1 ? 'block' : 'none' } },
            `To convert Ug to ${conversionFullName}, enter the Ug value in the input field. The converted value will be displayed automatically.`
          )
        )
      )
    )
  );
}

function getConversionFormula(conversionId) {
  switch (conversionId) {
    case 'ug-to-g':
      return '1 μg = 0.000001 g';
    case 'ug-to-mg':
      return '1 μg = 0.001 mg';
    case 'ug-to-ng':
      return '1 μg = 1000 ng';
    case 'ug-to-kg':
      return '1 μg = 0.000000001 kg';
    case 'ug-to-ml':
      return '1 μg = 0.001 ml';
    case 'ug-to-iu':
      return '1 μg = 0.001 IU';
    case 'ug-to-ul':
      return '1 μg = 1 μl';
    default:
      return '';
  }
}

export default ConversionPage;


