import React from 'react';
import { Link } from 'react-router-dom';
import './Header.css';

function Header() {
  return (
    <header className="header">
      <h1>
        <Link to="/">Ug Conversion</Link>
      </h1>
      <nav className="header-menu">
        <Link to="/contact">Contact</Link>
        <Link to="/about">About</Link>
        <Link to="/privacy">Policy</Link>
        <div className="dropdown">
          <Link to="#">All Conversions</Link>
          <div className="dropdown-content">
            <Link to="/ug-to-g">Ug to Grams (g)</Link>
            <Link to="/ug-to-mg">Ug to Milligrams (mg)</Link>
            <Link to="/ug-to-ng">Ug to Nanograms (ng)</Link>
            <Link to="/ug-to-kg">Ug to Kilograms (kg)</Link>
            <Link to="/ug-to-ml">Ug to Milliliters (ml)</Link>
            <Link to="/ug-to-iu">Ug to International Units (IU)</Link>
            <Link to="/ug-to-ul">Ug to Microliters (ul)</Link>
          </div>
        </div>
      </nav>
    </header>
  );
}

export default Header;